

import React from "react";
import styled from "styled-components";


const DvacetCtyriInner = styled.div`
  .kDispozici {
    width: fit-content;
    margin: 0 auto;

    .greenDot {
      background: #49B25D;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      animation: pulse 2s infinite;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #48B15C;
      line-height: 24px;
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 1;
    }
  }
`;

const DvacetCtyri = ({ className }) => {
  
  return (
    <DvacetCtyriInner className={className}>
      <div className="kDispozici">
        <div className="greenDot"></div>
        <span>K dispozici 24/7</span>
      </div>
    </DvacetCtyriInner>
  );
}

export default DvacetCtyri;
