import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import Jumbotron from "../../components/pohrby/Jumbotron";
import Poplatky from "../../components/pohrby/Poplatky";
import Img from "gatsby-image";
import LinkButton from "../../components/pohrby/CallButton";
import DvacetCtyri from "../../components/DvacetCtyri";
import { useMediaQuery } from 'react-responsive';
import Mapa from "../../assets/img/mapaPokryti.svg";
import PokrytiSipka from "../../assets/img/pokrytiSipka.png";
import SeVsimPomuzeme from "../../components/pohrby/SeVsimPomuzeme";
import Zpatky from "../../assets/img/zpatky.svg";

const PokrytiInner = styled.div`
  .zpetButton {
    display: flex;
    margin: 0;
    margin-bottom: 20px;
    width: calc(100% - 50px);
    align-items: center;
    text-decoration: none;
    max-width: 600px;
    background: none !important;
    
    img {
      margin-right: 10px;
    }

    span {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #828282;

      ${props => props.isDark && "color: rgba(255,255,255,0.7);"}
    }
  }

  .jumbotron {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 100px auto;
    margin-bottom: 170px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      display: block;
      margin: 40px auto;
      max-width: 600px;
      margin-bottom: 90px;
    }

    .left {
      max-width: 610px;
      width: calc(100% - 500px);

      @media (max-width: 1100px) {
        width: calc(100% - 400px);
      }

      @media (max-width: 950px) {
        width: calc(100% - 340px);
      }

      @media (max-width: 899px) {
        max-width: none;
        width: calc(100%);
      }

      h1 {
        font-family: NewKansas;
        font-size: 32px;
        color: #243A58;
        margin-top: 20px;

        @media (max-width: 899px) {
          font-size: 29px;
          line-height: 42px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #4A4A4A;
        line-height: 28px;
        margin-top: 35px;

        @media (max-width: 899px) {
          font-size: 16px;
          line-height: 25px;
          margin-top: 25px;
        }
      }

      .popisek {
        font-family: Visuelt-Bold;
        font-size: 16px;
        color: #000000;
        line-height: 28px;
        margin-bottom: 15px;
        margin-top: 40px;
        display: block;
      }

      .callToAction {
        display: flex;
        width: 100%;
        align-items: center;

        @media (max-width: 899px) {
          display: block;
        }

        a {
          min-width: 250px;
        }

        .kDispozici {
          margin-left: 30px;

          @media (max-width: 899px) {
            margin-left: 0px;
            margin-top: 15px;
          }
        }
      }
    }

    .right {
      max-width: 450px;

      @media (max-width: 1100px) {
        max-width: 350px;
      }

      @media (max-width: 950px) {
        max-width: 300px;
      }

      @media (max-width: 899px) {
        max-width: 450px;
        margin-top: 60px;
      }
    }
  }

  .rozcestnik {
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: repeat(3, 1fr); 
    grid-column-gap: 50px;
    grid-row-gap: 90px;
    margin: 0 auto;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin-bottom: 120px;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .sloupec  {
      border-top: 2px solid #243A58;
      padding-top: 25px;

      h2 {
        font-family: Visuelt-Bold;
        font-size: 22px;
        color: #000000;
        margin-bottom: 20px;
      }

      ul {
        padding: none;
        li {
          margin-top: 15px;
          list-style: none;

          .lokace {
            span {
              font-family: Visuelt-Regular;
              font-size: 18px;
              color: #848484;
            }
          }

          a {
            display: flex;
            text-decoration: none;
            font-family: Visuelt-Regular;
            font-size: 18px;
            color: #848484;
            align-items: center;

            &:hover {
              text-decoration: underline;
            }

            span {
              color: #848484;
            }

            img {
              margin-left: 8px;
              height: 10px;
            }
          }
        }
      }
    }
  }
`;



const Pokryti = () => {

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  return (
    <Layout
      subMenuLong
      forcePohrbyMenu
      subMenuItems={[
        {
          name: "Pohřební služba",
          url: "/pohrebni-sluzba/",
        },
        {
          name: "Kde fungujeme?",
          url: "/pobocky/",
        },
        {
          name: "Parte",
          url: "/parte/",
        },
        {
          name: "Kremace bez obřadu",
          url: "/kremace-bez-obradu/",
        },
        {
          name: "Pohřeb s obřadem",
          url: "/pohreb-s-obradem/",
        },
        {
          name: "Netradiční pohřeb",
          url: "/netradicni-pohreb/",
        },
        {
          name: "Pohřeb miminka",
          url: "/pohreb-miminka/",
        },
      ]}
    >
      <SEO
        title="Ve kterých městech fungujeme? | Pohřební služba Goodbye.cz"
        customTitle
        description="Pohřeb vám aktuálně pomůžeme naplánovat v několika městech po celé ČR: Praha, Brno a blízké okolí. V případě potřeby jsme schopni zajet kamkoliv."
        image={"/pohrebnisluzbaOg.png"}
      />

      

      <PokrytiInner>

        <section className="jumbotron">
          <div className="left">
            <Breadscrum
              visible={true}
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Kde fungujeme?", url: "/pobocky/"}
              ]}
            />
            <h1>Kde naše pohřební služba funguje?</h1>
            <p>Pohřeb vám aktuálně pomůžeme naplánovat v několika městech po celé ČR. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.</p>
            <span className="popisek">Pojďme si nezávazně zavolat</span>
            <div className="callToAction">
              <LinkButton />
              <DvacetCtyri />
            </div>
          </div>

          <div className="right">
            <img alt="Mapa pokrytí Goodbye.cz" src={Mapa} />
          </div>
        </section>


        <section className="rozcestnik">

          <div className="sloupec">
            <h2>Praha</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-praha/">
                  <span>Praha</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-2/">
                  <span>Praha 2 - Nové Město</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-3/">
                  <span>Praha 3 - Vinohrady</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-4/">
                  <span>Praha 4 - Krč, Modřany</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-5/">
                  <span>Praha 5 - Motol, Smíchov</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-6/">
                  <span>Praha 6 - Břevnov, Střešovice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-8/">
                  <span>Praha 8 - Bulovka</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-9/">
                  <span>Praha 9 - Čakovice, Vysočany</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha/praha-10/">
                  <span>Praha 10 - Strašnice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>


          <div className="sloupec">
            <h2>Středočeský kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-kladno/">
                  <span>Kladno</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-kladno/slany/">
                  <span>Slaný</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-ricany/">
                  <span>Říčany</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-sazava/">
                  <span>Sázava</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-kolin/">
                  <span>Kolín</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-kutna-hora/">
                  <span>Kutná Hora</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-podebrady/">
                  <span>Poděbrady</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-caslav/">
                  <span>Čáslav</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>


              {/* <li>
                <Link to="/pohrebni-sluzba-praha-vychod/">
                  <span>Praha-východ</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-praha-zapad/">
                  <span>Praha-západ</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="sloupec">
            <h2>Jihomoravský kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-brno/">
                  <span>Brno</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-brno/jihlavska/">
                  <span>Brno Jihlavská</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-brno/koliste/">
                  <span>Brno Koliště</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-brno/lisen/">
                  <span>Brno Líšeň</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-brno/bohunice/">
                  <span>Brno Bohunice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-brno/venkov/">
                  <span>Brno venkov</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-brno/videnska/">
                  <span>Brno Vídeňská</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="sloupec">
            <h2>Pardubický kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-pardubice/">
                  <span>Pardubice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-pardubice/dubina/">
                  <span>Pardubice Dubina</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="sloupec">
            <h2>Plzeňský kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-plzen/">
                  <span>Plzeň</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="sloupec">
            <h2>Severočeský kraj</h2>
            <ul>
              <li>
                <div className="lokace">
                  <span>Připravujeme...</span>
                </div>
              </li>
            </ul>
          </div> */}

          <div className="sloupec">
            <h2>Vysočina</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-jihlava/">
                  <span>Jihlava</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-jihlava/zizkova/">
                  <span>Jihlava Žižkova</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="sloupec">
            <h2>Ústecký kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-usti-nad-labem/">
                  <span>Ústí nad Labem</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-most/">
                  <span>Most</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-teplice/">
                  <span>Teplice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-decin/">
                  <span>Děčín</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-chomutov/">
                  <span>Chomutov</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-litomerice/">
                  <span>Litoměřice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              
              
            </ul>
          </div>

          <div className="sloupec">
            <h2>Liberecký kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-liberec/">
                  <span>Liberec</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-liberec/valdstejnska/">
                  <span>Liberec Valdštejnská</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-jablonec-nad-nisou/">
                  <span>Jablonec nad Nisou</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="sloupec">
            <h2>Jihočeský kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-ceske-budejovice/">
                  <span>České Budějovice</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-pisek/">
                  <span>Písek</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>

              <li>
                <Link to="/pohrebni-sluzba-cesky-krumlov/">
                  <span>Český Krumlov</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="sloupec">
            <h2>Moravskoslezský kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-ostrava/">
                  <span>Ostrava</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-havirov/">
                  <span>Havířov</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/pohrebni-sluzba-opava/">
                  <span>Opava</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="sloupec">
            <h2>Zlínský kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-uherske-hradiste/">
                  <span>Uherské Hradiště</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="sloupec">
            <h2>Královéhradecký kraj</h2>
            <ul>
              <li>
                <Link to="/pohrebni-sluzba-hradec-kralove/">
                  <span>Hradec Králové</span>
                  <img src={PokrytiSipka} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <SeVsimPomuzeme />
      </PokrytiInner>      

    </Layout>
  );
}

export default Pokryti;
