import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import SipkaPoplatky from "../../assets/img/sipkaPoplatky.svg";


const PoplatkyInner = styled.div`
  .poplatky {
    position: relative;
    
    button {
      cursor: pointer;
      background: none;
      border: 0;
      padding: 0;
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #333333;
      text-decoration: underline;
      vertical-align: middle;
      text-align: left;

      img {
        margin-left: 10px;
        vertical-align: middle;
        transition: all 0.3s;
      }
    }

    p {
      position: absolute;
      top: calc(100% - 20px);
      left: 0;
      font-family: Visuelt-Regular;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-shadow: 0 3px 15px 0 rgba(0,0,0,0.13);
      border-radius: 5px;
      font-size: 16px;
      color: #333333;
      line-height: 25px;
      padding: 20px;
    }
  }

  .hidden {
    p {
      display: none;
    }

    img {
      transform: rotate(180deg);
      transition: all 0.3s;
    }
  }
`;


const NoWrap = styled.span`
  white-space: nowrap;
`;



const Poplatky = () => {

  const [poplatkyVisible, setPoplatkyVisible] = useState(false);

  const togglePoplatky = () => {
    if (poplatkyVisible) {
      setPoplatkyVisible(false);
    }

    else {
      setPoplatkyVisible(true);
    }
  }

  const isPlus1100 = useMediaQuery({
    query: '(min-width: 1100px)'
  });

  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });
  
  const isPlus360 = useMediaQuery({
    query: '(min-width: 360px)'
  });


  return (
    <PoplatkyInner>
      <div className={(!poplatkyVisible) ? "poplatky hidden" : "poplatky"}>
        <button onClick={() => togglePoplatky()}>Mohou se vás týkat poplatky za <NoWrap>chlazení? <img src={SipkaPoplatky} alt="" /></NoWrap></button>
        <p>Pokud zařizujete pohřeb více než 48 hodin od úmrtí, účtuje si instituce, ve které váš zesnulý zemřel, poplatky za chlazení. Částka záleží na konkrétní instituci a počtu dní. Rádi za vás tuto částku zjistíme a vyřešíme platbu.</p>
      </div>
    </PoplatkyInner>
  );
}

export default Poplatky;


